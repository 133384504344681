<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button @click="handleGoBack">返回</a-button>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, index">
        {{ index + 1 }}
      </span>
    </s-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { car_read } from '@/api/car'
import { Dictionaries } from '@/common/AllConstant'

export default {
  name: 'Read',
  components: {
    STable
  },
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '车牌号',
          dataIndex: 'number',
          ellipsis: true
        },
        {
          title: '创建人',
          dataIndex: 'create_by',
          ellipsis: true
        },
        {
          title: '操作',
          customRender: (text) => this.$Dictionaries.RecordAction[text],
          dataIndex: 'action'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const number = this.record.number || this.$route.query.number
        return car_read(Object.assign(parameter, this.queryParam, { number: number }))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {},
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
